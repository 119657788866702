body {
	margin: 0;
	font-family: 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

small .progress {
	--bs-progress-height: 0.5rem;
}

a {
	text-decoration: none;
}

h5 {
	font-size: 100%;
	text-decoration: underline;
	margin-top: 0.2rem;
}

pre {
	margin: 0;
}

pre.code,
code {
	color: #779de1;
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

pre.code {
	display: block;
	font-size: 80%;
}

.smart-text-box {
	overflow: auto;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

.vertical-align-center {
	vertical-align: middle;
}

.pagination {
	margin: 0;
}

.cursor-pointer {
	cursor: pointer;
}

table .long-col {
	word-wrap: anywhere;
	overflow: auto;
	font-size: 80%;
}

.cool-table tr:last-child td {
	border-bottom-width: 0;
}

.cool-table {
	margin-bottom: 0;
}

.alert-box {
	position: fixed;
	right: 0;
	bottom: 0;
	z-index: 999999999;
}

.limited-width {
	max-width: 770px;
}

form .form-check {
	margin-bottom: 0;
}
